import React from "react";
import HelpAndSupport from "../screens/Dashboard/Profile/HelpAndSupport";

const Home = React.lazy(() => import("../screens/Dashboard"));
const Task = React.lazy(() => import("../screens/Dashboard/Task"));
const Wallet = React.lazy(() => import("../screens/Dashboard/Wallet"));
const Earning = React.lazy(() => import("../screens/Dashboard/Earnings"));
const RedeemCoupon = React.lazy(() =>
  import("../screens/Dashboard/Wallet/RedeemCoupon")
);
const Referral = React.lazy(() => import("../screens/Dashboard/Referral"));
const Withdraw = React.lazy(() =>
  import("../screens/Dashboard/Wallet/withdraw")
);
// Social Media Task
const FacebookTask = React.lazy(() =>
  import("../screens/Dashboard/SocialMediaTask/FacebookTask")
);
const InstagramTask = React.lazy(() =>
  import("../screens/Dashboard/SocialMediaTask/InstagramTask")
);
const LinkedinTask = React.lazy(() =>
  import("../screens/Dashboard/SocialMediaTask/LinkedinTask")
);
const TelegramTask = React.lazy(() =>
  import("../screens/Dashboard/SocialMediaTask/TelegramTask")
);
const TwitterTask = React.lazy(() =>
  import("../screens/Dashboard/SocialMediaTask/TwitterTask")
);
const YoutubeTask = React.lazy(() =>
  import("../screens/Dashboard/SocialMediaTask/YoutubeTask")
);
const WhatsAppTask = React.lazy(() =>
  import("../screens/Dashboard/SocialMediaTask/WhatsAppTask")
);
const ReviewTask = React.lazy(() =>
  import("../screens/Dashboard/SocialMediaTask/ReviewTask")
);
const OthersTask = React.lazy(() =>
  import("../screens/Dashboard/SocialMediaTask/OthersTask")
);
const TaskDetails = React.lazy(() =>
  import("../screens/Dashboard/SocialMediaTask/TaskDetails")
);
const Premium = React.lazy(() => import("../screens/Dashboard/Premium"));
const Success = React.lazy(() => import("../screens/Dashboard/Premium/Payment Status/Success"));
const Failiure = React.lazy(() => import("../screens/Dashboard/Premium/Payment Status/Failiure"));
const MyLeads = React.lazy(() => import("../screens/Dashboard/MyLeads"));
const AllPointHistory = React.lazy(() =>
  import("../screens/Dashboard/LeaderBoard/AllPointHistory")
);
const Redeem = React.lazy(() => import("../screens/Dashboard/Redeem/Redeem"))
const MyProfile = React.lazy(() =>
  import("../screens/Dashboard/Profile/MyProfile")
);
const ManagePayment = React.lazy(() =>
  import("../screens/Dashboard/Profile/ManagePayment/ManagePayment")
);
const KYC = React.lazy(() =>
  import("../screens/Dashboard/Profile/KYC")
);
const LeaderBoard = React.lazy(() =>
  import("../screens/Dashboard/LeaderBoard")
);
const MysteryBox = React.lazy(() =>
  import("../screens/Dashboard/LeaderBoard/MysteryBox")
);
const LeaderBoardHistory = React.lazy(() =>
  import("../screens/Dashboard/LeaderBoard/LeaderBoardHistory")
);
const CreateTask = React.lazy(() => import("../screens/Dashboard/CreateTask"));
const ManageTask = React.lazy(() => import("../screens/Dashboard/ManageTask"));

// 404
const Page404 = React.lazy(() => import("../screens/Page404"));

export const routers = [
  {
    path: "*",
    exact: true,
    name: "Error",
    permission: ["user"],
    component: Page404,
  },
  {
    path: "/",
    exact: true,
    name: "Dashboard",
    permission: ["user"],
    component: Home,
  },
  {
    path: "/task",
    exact: true,
    name: "Task",
    permission: ["user"],
    component: Task,
  },
  {
    path: "/wallet",
    exact: true,
    name: "Wallet",
    permission: ["user"],
    component: Wallet,
  },
  {
    path: "/earning",
    exact: true,
    name: "Earning",
    permission: ["user"],
    component: Earning,
  },
  {
    path: "/redeem-coupon",
    exact: true,
    name: "Redeem Coupon",
    permission: ["user"],
    component: RedeemCoupon,
  },
  {
    path: "/referral",
    exact: true,
    name: "Referral",
    permission: ["user"],
    component: Referral,
  },
  {
    path: "/withdraw",
    exact: true,
    name: "Withdraw",
    permission: ["user"],
    component: Withdraw,
  },
  // Social Media Task
  {
    path: "/facebook-task",
    exact: true,
    name: "Facebook Task",
    permission: ["user"],
    component: FacebookTask,
  },
  {
    path: "/instagram-task",
    exact: true,
    name: "Instagram Task",
    permission: ["user"],
    component: InstagramTask,
  },
  {
    path: "/telegram-task",
    exact: true,
    name: "Telegram Task",
    permission: ["user"],
    component: TelegramTask,
  },
  {
    path: "/linkedin-task",
    exact: true,
    name: "Linkedin Task",
    permission: ["user"],
    component: LinkedinTask,
  },
  {
    path: "/youtube-task",
    exact: true,
    name: "Youtube Task",
    permission: ["user"],
    component: YoutubeTask,
  },
  {
    path: "/twitter-task",
    exact: true,
    name: "Twitter Task",
    permission: ["user"],
    component: TwitterTask,
  },
  {
    path: "/whatsapp-task",
    exact: true,
    name: "WhatsApp Task",
    permission: ["user"],
    component: WhatsAppTask,
  },
  {
    path: "/review-task",
    exact: true,
    name: "Review Task",
    permission: ["user"],
    component: ReviewTask,
  },
  {
    path: "/others-task",
    exact: true,
    name: "Others Task",
    permission: ["user"],
    component: OthersTask,
  },
  {
    path: "/task-details",
    exact: true,
    name: "Task Details",
    permission: ["user"],
    component: TaskDetails,
  },
  {
    path: "/premium",
    exact: true,
    name: "Premium",
    permission: ["user"],
    component: Premium,
  },
  {
    path: "/premium/payment-status/success",
    exact: true,
    name: "Payment Successfull",
    permission: ["user"],
    component: Success,
  },
  {
    path: "/premium/payment-status/failiure",
    exact: true,
    name: "Payment Failed",
    permission: ["user"],
    component: Failiure,
  },
  {
    path: "/my-leads",
    exact: true,
    name: "My Leads",
    permission: ["user"],
    component: MyLeads,
  },
  {
    path: "/all-point-history",
    exact: true,
    name: "All Point History",
    permission: ["user"],
    component: AllPointHistory,
  },
  {
    path: "/all-point-history/redeem",
    exact: true,
    name: "Redeem",
    permission: ["user"],
    component: Redeem,
  },
  {
    path: "/my-profile",
    exact: true,
    name: "My Profile",
    permission: ["user"],
    component: MyProfile,
  },
  {
    path: "/help-and-support",
    exact: true,
    name: "Help and Support",
    permission: ["user"],
    component: HelpAndSupport,
  },
  {
    path: "/my-profile/manage-payment",
    exact: true,
    name: "Manage Payment",
    permission: ["user"],
    component: ManagePayment,
  },
  {
    path: "/my-profile/kyc",
    exact: true,
    name: "KYC",
    permission: ["user"],
    component: KYC,
  },
  {
    path: "/leader-board",
    exact: true,
    name: "Leader Board",
    permission: ["user"],
    component: LeaderBoard,
  },
  {
    path: "/mystery-box",
    exact: true,
    name: "Mystery Box",
    permission: ["user"],
    component: MysteryBox,
  },
  {
    path: "/leader-board-history",
    exact: true,
    name: "Leader Board History",
    permission: ["user"],
    component: LeaderBoardHistory,
  },
  {
    path: "/create-task",
    exact: true,
    name: "Create Task",
    permission: ["user"],
    component: CreateTask,
  },
  {
    path: "/manage-task",
    exact: true,
    name: "Manage Task",
    permission: ["user"],
    component: ManageTask,
  },
];
