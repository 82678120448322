// import React, { useEffect, useRef, useState } from "react";
// import { IoIosLogOut, IoMdNotifications } from "react-icons/io";
// import { removeLocalStorage } from "./../../utils/function/localStorage";
// import {
//   useClaimBtnQuery,
//   useGetTotalPointsQuery,
//   useGetWalletQuery,
//   useMyProfileQuery,
//   useGetNotificationsQuery,
// } from "../../services/userApi";
// import Popover from "../Popover";
// import AvatarDropdownMenu from "../avatarDropdownMenu";
// import { useClickOutside } from "./../../hooks/useClickOutside";
// import { useNavigate } from "react-router-dom";
// import NotificationModal from "../Modals/NotificationModal"; // Import NotificationModal
// import { io } from 'socket.io-client';
// import { env } from "../../env";


// const Header = ({ screenName, isGiftShow }) => {
//   const navigate = useNavigate();
//   const bellIconRef = useRef(null);
//   const { data: profile } = useMyProfileQuery();
//   const { data: claimBtn } = useClaimBtnQuery();
//   const { data: getTotalPoints } = useGetTotalPointsQuery();
//   const { data: getWallet } = useGetWalletQuery();
//   const { data: notifications } = useGetNotificationsQuery("bell icon");
//   const [modalPosition, setModalPosition] = useState({ top: 0, left: 0 });
//   // console.log(notifications?.data)
//   const profileRef = useRef(null);
//   const [openMenu, setOpenMenu] = useState(false);
//   const [modalIsOpen, setModalIsOpen] = useState(false);
//   const [Notification, SetNotification] = useState([])
//   useClickOutside(profileRef, () => setOpenMenu(false));
  
//   const socket = io(env.BASE_URL, {
//     transports: ['websocket'], 
//   });
  
//   useEffect (() =>{
//     console.log("notification data ",notifications?.data)
//     SetNotification(notifications?.data)
//   }, [notifications]) 
    

//   useEffect(() => {
//     socket.on('newNotification', (notification) => {
//       console.log('Received new notification:', notification);
//       // Update your notification state or UI with this new notification
//       SetNotification(prevNotifications => [...prevNotifications, notification]);
//     });

//     // Clean up the socket connection on component unmount
//     return () => {
//       socket.disconnect();
//     };
//   }, [socket]);

//   const handleLogout = () => {
//     removeLocalStorage("tp_Aa_uth_access");
//     window.location.reload();
//   };

//   const openNotificationModal = () => {
//     const rect = bellIconRef.current.getBoundingClientRect();
//     setModalPosition({ top: rect.bottom + 20, left: rect.left - 300 });
//     setModalIsOpen(true);
//   };

//   const closeNotificationModal = () => {
//     setModalIsOpen(false);
//   };

//   return (
    
//     <div className="tpu__dashboard__header__wrapper">
//       <div className="tpu__dashboard__header__widgets">
//         <div className="tpu__dashboard__header__element">
//           <p>{screenName}</p>
//         </div>
//         <div className="tpu__dashboard__header__element">
//           <div className="tpu__dashboard__header__widgets">
//             <div className="tpu__dashboard__header__icon" onClick={() => navigate("/home/all-point-history")}>
//               <div className="claimImg">
//                 <img
//                   src={claimBtn?.data ? "https://res.cloudinary.com/djfzcmvmr/image/upload/v1723092362/TaskPlanet/kuu_e4axkq.png" : "https://res.cloudinary.com/djfzcmvmr/image/upload/v1723092366/TaskPlanet/lllkk_tlu5v5.png"}
//                   alt=""
//                 />
//                 <p className="Header__points">{getTotalPoints?.totalPoints}</p>
//               </div>
//             </div>
//             <div className="tpu__dashboard__header__icon" onClick={() => navigate("/home/wallet")}>
//               <div className="claimImg" style={{ background: "#e4f6ea" }}>
//                 <p className="Header__points" style={{ color: "#42a755" }}>
//                   ₹{getWallet?.data?.current_balance?.toFixed(2)}
//                 </p>
//               </div>
//             </div>
//             {isGiftShow && (
//               <div className="tpu__dashboard__header__icon" onClick={() => navigate("/home/referral")}>
//                 <div className="claimImg">
//                   <p className="Header__points" style={{ color: "#575757" }}>
//                     ₹1000
//                   </p>
//                 </div>
//               </div>
//             )}
//             <div
//               ref={bellIconRef}
//               className="tpu__dashboard__header__icon"
//               onClick={openNotificationModal}
//               style={{ color: 'red' }}
//             >
//               <IoMdNotifications size={24} />
//             </div>
//             {/* Notification Modal */}
//             <NotificationModal
//                     isOpen={modalIsOpen}
//                     onClose={closeNotificationModal}
//                     notifications={Notification}
//                     SetNotification = {SetNotification}
//                     style={{
//                       top: `${modalPosition.top}px`,
//                       left: `${modalPosition.left}px`,
//                     }}
//                   />
//             <div ref={profileRef} className="tpu__dashboard__header__icon">
//               <img
//                 src={profile?.data[0]?.profile_pic ? profile?.data[0]?.profile_pic : "https://res.cloudinary.com/green-valley-grocery/image/upload/v1667014541/tp-app-assests/icons/profile_nadfzb.png"}
//                 alt="profilePic"
//                 className="header__profilePic"
//                 onClick={() => setOpenMenu(!openMenu)}
//               />
//               <Popover openPopover={openMenu} className="clickable_submenu">
//                 <AvatarDropdownMenu
//                   setOpenMenu={setOpenMenu}
//                   logout={handleLogout}
//                   data={profile?.data[0]}
//                 />
//               </Popover>
//             </div>
//           </div>
//         </div>
//       </div>

      
//     </div>
//   );
// };

// export default Header;


import React, { useEffect, useRef, useState } from "react";
import { IoIosLogOut, IoMdNotifications } from "react-icons/io";
import { removeLocalStorage } from "./../../utils/function/localStorage";
import {
  useClaimBtnQuery,
  useGetTotalPointsQuery,
  useGetWalletQuery,
  useMyProfileQuery,
  useGetNotificationsQuery,
} from "../../services/userApi";
import Popover from "../Popover";
import AvatarDropdownMenu from "../avatarDropdownMenu";
import { useClickOutside } from "./../../hooks/useClickOutside";
import { useNavigate } from "react-router-dom";
import NotificationModal from "../Modals/NotificationModal"; // Import NotificationModal
import { io } from 'socket.io-client';
import { env } from "../../env";

const Header = ({ screenName, isGiftShow }) => {
  const navigate = useNavigate();
  const bellIconRef = useRef(null);
  const { data: profile } = useMyProfileQuery();
  const { data: claimBtn } = useClaimBtnQuery();
  const { data: getTotalPoints } = useGetTotalPointsQuery();
  const { data: getWallet } = useGetWalletQuery();
  const { data: notifications } = useGetNotificationsQuery("bell icon");
  const [modalPosition, setModalPosition] = useState({ top: 0, left: 0 });
  const profileRef = useRef(null);
  const [openMenu, setOpenMenu] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [notificationList, setNotificationList] = useState([]);
  const [isBellIconActive, setIsBellIconActive] = useState(false); // State for bell icon color
  useClickOutside(profileRef, () => setOpenMenu(false));
  
  const socket = io(env.BASE_URL, {
    transports: ['websocket'], 
  });
  
  useEffect(() => {
    console.log("notification data ", notifications?.data);
    setNotificationList(notifications?.data);
  }, [notifications]);
  
  useEffect(() => {
    socket.on('newNotification', (notification) => {
      console.log('Received new notification:', notification);
      // Update your notification state or UI with this new notification
      setNotificationList(prevNotifications => [...prevNotifications, notification]);
    });

    // Clean up the socket connection on component unmount
    return () => {
      socket.disconnect();
    };
  }, [socket]);

  const handleLogout = () => {
    removeLocalStorage("tp_Aa_uth_access");
    window.location.reload();
  };

  const toggleNotificationModal = () => {
    const rect = bellIconRef.current.getBoundingClientRect();
    setModalPosition({ top: rect.bottom + 20, left: rect.left - 300 });
    
    setModalIsOpen(!modalIsOpen);
    setIsBellIconActive(!isBellIconActive); // Toggle the bell icon color
  };

  const closeNotificationModal = () => {
    setModalIsOpen(false);
    setIsBellIconActive(false); // Reset the bell icon color when modal closes
  };

  return (
    <div className="tpu__dashboard__header__wrapper">
      <div className="tpu__dashboard__header__widgets">
        <div className="tpu__dashboard__header__element">
          <p>{screenName}</p>
        </div>
        <div className="tpu__dashboard__header__element">
          <div className="tpu__dashboard__header__widgets">
            <div className="tpu__dashboard__header__icon" onClick={() => navigate("/home/all-point-history")}>
              <div className="claimImg">
                <img
                  src={claimBtn?.data ? "https://res.cloudinary.com/djfzcmvmr/image/upload/v1723092362/TaskPlanet/kuu_e4axkq.png" : "https://res.cloudinary.com/djfzcmvmr/image/upload/v1723092366/TaskPlanet/lllkk_tlu5v5.png"}
                  alt=""
                />
                <p className="Header__points">{getTotalPoints?.totalPoints}</p>
              </div>
            </div>
            <div className="tpu__dashboard__header__icon" onClick={() => navigate("/home/wallet")}>
              <div className="claimImg" style={{ background: "#e4f6ea" }}>
                <p className="Header__points" style={{ color: "#42a755" }}>
                  ₹{getWallet?.data?.current_balance?.toFixed(2)}
                </p>
              </div>
            </div>
            {isGiftShow && (
              <div className="tpu__dashboard__header__icon" onClick={() => navigate("/home/referral")}>
                <div className="claimImg">
                  <p className="Header__points" style={{ color: "#575757" }}>
                    ₹1000
                  </p>
                </div>
              </div>
            )}
            <div
              ref={bellIconRef}
              className="tpu__dashboard__header__icon"
              onClick={toggleNotificationModal}
              style={{ color: isBellIconActive ? 'black' : '#8c8787' }} // Conditionally change color
            >
              <IoMdNotifications size={24} />
            </div>
            {/* Notification Modal */}
            <NotificationModal
              isOpen={modalIsOpen}
              onClose={closeNotificationModal}
              notifications={notificationList}
              setNotificationList={setNotificationList}
              style={{
                top: `${modalPosition.top}px`,
                left: `${modalPosition.left}px`,
              }}
            />
            <div ref={profileRef} className="tpu__dashboard__header__icon">
              <img
                src={profile?.data[0]?.profile_pic ? profile?.data[0]?.profile_pic : "https://res.cloudinary.com/green-valley-grocery/image/upload/v1667014541/tp-app-assests/icons/profile_nadfzb.png"}
                alt="profilePic"
                className="header__profilePic"
                onClick={() => setOpenMenu(!openMenu)}
              />
              <Popover openPopover={openMenu} className="clickable_submenu">
                <AvatarDropdownMenu
                  setOpenMenu={setOpenMenu}
                  logout={handleLogout}
                  data={profile?.data[0]}
                />
              </Popover>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
