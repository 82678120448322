import React from "react";
import { FaBusinessTime, FaChevronRight } from "react-icons/fa";
import { MdLocationPin } from "react-icons/md";
import "./Career.css";

const Careers = () => {
  const data = [
    {
      designation: "Full Stack Developer",
      timing: "Full Time",
      location: "Remote",
      description:
        "Seeking skilled full stack developers proficient in front-end and back-end technologies to join our team. Apply now for available vacancies!",
    },
    {
      designation: "Backend Developer",
      timing: "Full Time",
      location: "Remote",
      description:
        "Join our team as a backend developer. Proficiency in server-side technologies required. Apply now for available vacancies!",
    },
    {
      designation: "Web Designer",
      timing: "Full Time",
      location: "Mumbai, India",
      description:
        "Join our team as a web designer. Create stunning, user-friendly interfaces. Proficiency in design tools required. Apply now for available vacancies!",
    },
    {
      designation: "Digital Marketing",
      timing: "Full Time",
      location: "Mumbai, India",
      description:
        "Exciting opportunity for digital marketers! Join us to strategize, execute campaigns, and drive brand growth. Apply now for available vacancies!",
    },
    {
      designation: "Technical Support Agent",
      timing: "Full Time",
      location: "Mumbai, India",
      description:
        "Join our team as a Technical Support Agent. Provide expert assistance, troubleshooting, and solutions to customers. Apply now for available vacancies!",
    },
    {
      designation: "Accountant",
      timing: "Full Time",
      location: "Mumbai, India",
      description:
        "Join our team as an Accountant. Manage financial records, analysis, and reporting. Proficiency in accounting software required. Apply now for available vacancies!",
    },
  ];

  return (
    <div className="careers-page">
      <div className="careers-container">
        <div className="careers-header-wrapper">
          <h2 className="careers-header">Available Vacancies</h2>
        </div>
        <div className="career-vacancies">
          {data.map((job, index) => (
            <div key={index} className="career-card">
              <div className="career-card-header">
                <h4 className="career-title">{job.designation}</h4>
              </div>
              <div className="career-card-body">
                <div className="career-details">
                  <div className="career-detail-item">
                    <FaBusinessTime className="career-icon" />
                    <p className="career-detail-text">{job.timing}</p>
                  </div>
                  <div className="career-detail-item">
                    <MdLocationPin className="career-icon" />
                    <p className="career-detail-text">{job.location}</p>
                  </div>
                </div>
                <p className="career-description">{job.description}</p>
                <button className="career-apply-btn">
                  Apply Now <FaChevronRight className="apply-icon" />
                </button>
              </div>
            </div>
          ))}
        </div>
        <div className="join-us">
          <button className="join-us-btn">JOIN US</button>
        </div>
      </div>
    </div>
  );
};

export default Careers;
