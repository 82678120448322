import React from 'react';
import {useNavigate} from 'react-router-dom'
import Modal from 'react-modal';
import '../../styles/component/modal/Notification_modal.css'; // Import CSS for styling
import { useUpdateNotificationStatusMutation } from '../../services/userApi';

const NotificationModal = ({ isOpen, onClose, notifications, SetNotification, style  }) => {
  const navigate = useNavigate()
  const [updateNotificationStatus] = useUpdateNotificationStatusMutation();
  

  const formatDate = (isoDate) => {
    const date = new Date(isoDate);
    return date.toLocaleString('en-IN', { timeZone: 'Asia/Kolkata' });
  };

  const handleSwipe = async (notificationId,dir) => {
    const notificationElement = document.getElementById(notificationId);
    if (notificationElement) {
      if(dir === "left")
        notificationElement.classList.add('swiped-Left'); // Add swiped class for animation
      else if(dir === "right")
        notificationElement.classList.add('swiped-right'); // Add swiped class for animation

    }

    

    setTimeout(async () => {
      await updateNotificationStatus({ id: notificationId });

      const updatedNotifications = notifications.filter(
        (notification) => notification._id !== notificationId
      );
      SetNotification(updatedNotifications);
    }, 300); // Wait for the animation to complete
  };

  const handleClick = async (notificationId, redirectUrl) =>{
    await updateNotificationStatus({ id: notificationId });
    navigate(`/home/${redirectUrl}`)
  }


  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Notifications"
      className="notification-modal"
      overlayClassName="notification-modal-overlay"
      style={{ content: style }} 
      ariaHideApp={false}
    >
      <div className="notifications-content">
        {notifications?.length ? (
          notifications.map((notification, index) => (
            <div
              key={index}
              id={notification._id} // Set id for swipe detection
              className="notification-item"
              onTouchStart={(e) => {
                e.currentTarget.dataset.swipeStart = e.touches[0].clientX;
              }}
              onTouchEnd={(e) => {
                const swipeEnd = e.changedTouches[0].clientX;
                const swipeStart = parseFloat(e.currentTarget.dataset.swipeStart);
                const swipeDistance = swipeEnd - swipeStart;

                if (swipeDistance < -50 ) {
                  handleSwipe(notification._id, "left");
                }
                else if(swipeDistance > 50){
                  handleSwipe(notification._id, "right");
                }
              }}
              onMouseDown={(e) => {
                console.log("touched started")
                e.currentTarget.dataset.swipeStart = e.clientX; // Use clientX for mouse
              }}
              onMouseUp={(e) => {
                console.log("touch end")
                const swipeEnd = e.clientX;
                const swipeStart = parseFloat(e.currentTarget.dataset.swipeStart);
                const swipeDistance = swipeEnd - swipeStart;

                if (swipeDistance < -50 ) {
                  handleSwipe(notification._id, "left");
                }
                else if(swipeDistance > 50){
                  handleSwipe(notification._id, "right");
                }
              }}
              onClick={() => handleClick(notification._id, notification.notificationRedirectLocation)}
            >
              <p className="notification-content">{notification.notificationContent}</p>
              <span className="notification-date">{formatDate(notification.createdAt)}</span>
            </div>
          ))
        ) : (
          <p className="no-notifications">No notifications available.</p>
        )}
      </div>
    </Modal>
  );
};

export default NotificationModal;

