import React from "react";
import { CiSettings } from "react-icons/ci";
import { FaHome, FaPlus } from "react-icons/fa";
import { FaRankingStar } from "react-icons/fa6";
import { RiTodoFill } from "react-icons/ri";
import { useLocation, useNavigate } from "react-router-dom";

const Menus = [
  { id: "158w8r5sf", name: "Home", icon: <FaHome />, route: "/home" },
  {
    id: "sf4sf5w8r",
    name: "Tasks",
    icon: <RiTodoFill />,
    route: "/home/task",
  },
  {
    id: "sf9w6rsf",
    name: "Leader Board",
    icon: <FaRankingStar />,
    route: "/home/leader-board",
  },
];

const BottomMenu = () => {
  const navigate = useNavigate();
  const route = useLocation();
  return (
    <div className="tpu__dashboard__bottomMenu">
      <div className="tpu__dashboard__bottomMenu__widgets">
        {Menus.map((menu, i) => (
          <div
            className="tpu__dashboard__menu"
            key={i + 258}
            onClick={() => {
              navigate(menu.route)
            }}
          >
            {route?.pathname === menu.route && <span className="red__circle"></span>}
            <div
              className="tpu__menu__icon"
              style={{ marginTop: route?.pathname === menu.route ? "-15px" : "0px" }}
            >
              {menu.icon}
            </div>
            <p
              className="tpu__menu__name"
              style={{
                display: route?.pathname === menu.route ? "block" : "none",
                opacity: route?.pathname === menu.route ? 1 : 0,
              }}
            >
              {menu.name}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BottomMenu;
