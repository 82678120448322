import React from "react";
import { FiLogOut, FiPhoneCall, FiUser } from "react-icons/fi";
import { AiOutlineUserSwitch } from "react-icons/ai";
import { BsWhatsapp } from "react-icons/bs";
import { FaCrown } from "react-icons/fa";
import { SlInfo } from "react-icons/sl";
import { TfiHeadphoneAlt } from "react-icons/tfi";
import CustomLink from "./CustomLink";
import { useGetValidateSponsorIdQuery } from "../services/userApi";
import { FaRankingStar } from "react-icons/fa6";
import { GrTasks } from "react-icons/gr";
import { CiSettings } from "react-icons/ci";
import { useNavigate } from "react-router-dom";


const AvatarDropdownMenu = ({ setOpenMenu, logout, data }) => {
  const navigate = useNavigate();
  // sponsor data
  const { data: sponsorData } = useGetValidateSponsorIdQuery(data?.sponsorid);

  const avatarMenu = [
    {
      id: "my7s88ersr",
      menu: "my profile",
      icon: <FiUser />,
      route: "/home/my-profile",
      permission: ["user"],
    },
  ];
  const avatarMenu2 = [
    // {
    //   id: "249rw5rw82",
    //   menu: "Leader Board",
    //   icon: <FaRankingStar />,
    //   route: "/home/leader-board",
    //   permission: ["user"],
    // },
    // {
    //   id: "sf8s2fs6f9ws",
    //   menu: "All Task History",
    //   icon: <GrTasks />,
    //   route: "/home/all-task-history",
    //   permission: ["user"],
    // },
    {
      id: "6sf9w9wrw",
      menu: "Help and Support",
      icon: <TfiHeadphoneAlt />,
      route: "/home/help-and-support",
      permission: ["user"],
    },
    {
      id: "6sf9w9wrw",
      menu: "About",
      icon: <SlInfo />,
      route: "/home/about",
      permission: ["user"],
    },
  ];

  const handleWhatsAppButtonClick = () => {
    const whatsappLink = `https://wa.me/${sponsorData?.data?.mobile_number}/`;
    window.open(whatsappLink);
  };

  return (
    <>
      <ul className="submenu">
        {/* <div className="header">
          <div className="img">
            <img
              src={
                data?.profile_pic
                  ? data?.profile_pic
                  : "https://res.cloudinary.com/djfzcmvmr/image/upload/v1706607581/TaskPlanet/user-avatar_xthwmd.png"
              }
              alt="img"
            />
          </div>
          <div className="name">
            <h4>{data?.first_name + " " + data?.last_name}</h4>
            <p>{data?.username}</p>
            <button
              className={
                data?.is_premium ? "act-btn activate" : "act-btn deactivate"
              }
            >
              {data?.is_premium ? (
                <span>
                  <FaCrown
                    style={{ color: "#ffa500", margin: "auto 2px 3px auto" }}
                  />{" "}
                  Premium
                </span>
              ) : (
                "Free"
              )}
            </button>
          </div>
        </div> */}
        {avatarMenu
          .filter((pt) => pt?.permission?.includes(data?.role))
          ?.map((drop) => {
            return (
              <li
                key={drop.id}
                className="submenu_list"
                onClick={() => setOpenMenu(false)}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <CustomLink href={drop.route} className="submenu_link">
                  {drop.icon}
                  &nbsp; {drop.menu}
                </CustomLink>
                <button
                  className={
                    data?.activation_status
                      ? "act-btn activate"
                      : "act-btn deactivate"
                  }
                >
                  {data?.activation_status ? "Active" : "Inactive"}
                </button>
              </li>
            );
          })}

        {/* {data?.role === "user" && (
          <li className="submenu_list">
            <CustomLink
              href="#"
              style={{ paddingBottom: "0px" }}
              className="submenu_link"
            >
              <AiOutlineUserSwitch />
              &nbsp; {data?.sponsorid}
            </CustomLink>
            <p className="sponsor">sponsor</p>
          </li>
        )} */}

        {/* {data?.role === "user" && (
          <li className="submenu_list">
            <CustomLink
              href="#"
              className="submenu_link"
              onClick={() =>
                window.open(`tel:${sponsorData?.data?.mobile_number}`)
              }
            >
              <FiPhoneCall />
              &nbsp; {sponsorData?.data?.mobile_number}
            </CustomLink>
          </li>
        )} */}

          {/* {data?.role === "user" && (
            <li className="submenu_list">
              <CustomLink
                href="#"
                className="submenu_link"
                onClick={() => handleWhatsAppButtonClick()}
              >
                <BsWhatsapp />
                &nbsp; {sponsorData?.data?.mobile_number}
              </CustomLink>
            </li>
          )} */}

        {avatarMenu2
          .filter((pt) => pt?.permission?.includes(data?.role))
          ?.map((drop) => {
            return (
              <li
                key={drop.id}
                className="submenu_list"
                onClick={() => setOpenMenu(false)}
              >
                <CustomLink href={drop.route} className="submenu_link">
                  {drop.icon}
                  &nbsp; {drop.menu}
                </CustomLink>
              </li>
            );
          })}
          
        <li className="submenu_list" onClick={() => setOpenMenu(false)}>
          <CustomLink
            href="#"
            onClick={() => {
              logout();
            }}
            className="submenu_link"
          >
            <FiLogOut />
            &nbsp; Logout
          </CustomLink>
        </li>
      </ul>
    </>
  );
};

export default AvatarDropdownMenu;
